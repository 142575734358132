@charset "UTF-8";
/* flexbox */
/*****************************************************
	Custom Responsive CSS  (12 colonnes)
/*****************************************************
/*****************************************************
/*****************************************************/
/* Grandeur max du site */
.c12 {
  clear: both;
  margin: 0 auto;
  max-width: 1200px; }
  .c12.gutter {
    padding-left: 15px;
    padding-right: 15px; }

.clearfix:after,
.c12:after {
  content: " ";
  /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.c11,
.c10,
.c9,
.c8,
.c7,
.c6,
.c5,
.c4,
.c3,
.c2,
.c1 {
  float: left;
  min-height: 1px; }

.c12 {
  width: 100%; }

.c11 {
  width: 91.6666%; }

.c10 {
  width: 83.3333%; }

.c9 {
  width: 75%; }

.c8 {
  width: 66.6666%; }

.c7 {
  width: 58.3333%; }

.c6 {
  width: 50%; }

.c5 {
  width: 41.6666%; }

.c4 {
  width: 33.3333%; }

.c3 {
  width: 25%; }

.c2 {
  width: 16.6666%; }

.c1 {
  width: 8.3333%; }

/*
	Not yet mobile
*/
@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .logo-text a {
    font-size: 1.6rem; }
  #masthead {
    padding-top: 30px;
    padding-bottom: 30px; }
    #masthead .middle-logo img {
      width: 300px; }
  .main-navigation .nav-menu .menu-item {
    margin: 0 15px; }
    .main-navigation .nav-menu .menu-item a {
      font-size: 1.8rem; }
    .main-navigation .nav-menu .menu-item .sub-nav {
      margin-top: 30px; } }

@media only screen and (max-width: 1200px) {
  .logo-text a {
    font-size: 11px; } }
  @media only screen and (max-width: 1200px) and (min-width: 768px) {
    .logo-text a {
      font-size: calc(11px + 5 * ((100vw - 768px) / 432)); } }
  @media only screen and (max-width: 1200px) and (min-width: 1200px) {
    .logo-text a {
      font-size: 16px; } }

@media only screen and (max-width: 1200px) {
  #masthead {
    padding-top: 20px;
    padding-bottom: 20px; }
    #masthead .middle-logo img {
      width: 180px; } }
    @media only screen and (max-width: 1200px) and (min-width: 768px) {
      #masthead .middle-logo img {
        width: calc(180px + 120 * ((100vw - 768px) / 432)); } }
    @media only screen and (max-width: 1200px) and (min-width: 1200px) {
      #masthead .middle-logo img {
        width: 300px; } }

@media only screen and (max-width: 1200px) {
  .main-navigation .nav-menu .menu-item {
    margin-left: 7px;
    margin-right: 7px; } }
  @media only screen and (max-width: 1200px) and (min-width: 768px) {
    .main-navigation .nav-menu .menu-item {
      margin-left: calc(7px + 8 * ((100vw - 768px) / 432));
      margin-right: calc(7px + 8 * ((100vw - 768px) / 432)); } }
  @media only screen and (max-width: 1200px) and (min-width: 1200px) {
    .main-navigation .nav-menu .menu-item {
      margin-left: 15px;
      margin-right: 15px; } }

@media only screen and (max-width: 1200px) {
  .main-navigation .nav-menu .menu-item {
    padding-top: 14px; } }
  @media only screen and (max-width: 1200px) and (min-width: 768px) {
    .main-navigation .nav-menu .menu-item {
      padding-top: calc(14px + 11 * ((100vw - 768px) / 432)); } }
  @media only screen and (max-width: 1200px) and (min-width: 1200px) {
    .main-navigation .nav-menu .menu-item {
      padding-top: 25px; } }

@media only screen and (max-width: 1200px) {
  .main-navigation .nav-menu .menu-item {
    border-top-width: 6px; } }
  @media only screen and (max-width: 1200px) and (min-width: 768px) {
    .main-navigation .nav-menu .menu-item {
      border-top-width: calc(6px + 2 * ((100vw - 768px) / 432)); } }
  @media only screen and (max-width: 1200px) and (min-width: 1200px) {
    .main-navigation .nav-menu .menu-item {
      border-top-width: 8px; } }

@media only screen and (max-width: 1200px) {
  .main-navigation .nav-menu .menu-item a {
    font-size: 14px; } }
  @media only screen and (max-width: 1200px) and (min-width: 768px) {
    .main-navigation .nav-menu .menu-item a {
      font-size: calc(14px + 4 * ((100vw - 768px) / 432)); } }
  @media only screen and (max-width: 1200px) and (min-width: 1200px) {
    .main-navigation .nav-menu .menu-item a {
      font-size: 18px; } }

@media only screen and (max-width: 1200px) {
  .main-navigation .nav-menu .menu-item .sub-nav {
    margin-top: 20px; } }

@media only screen and (max-width: 1024px) {
  #mmenu,
  #open-mmenu {
    display: block; }
  #masthead .main-navigation {
    display: none; } }

@media only screen and (max-width: 1000px) {
  .realisations .realisations-wrapper > .c3 {
    width: 33.3333%; } }

@media only screen and (max-width: 768px) {
  html {
    font-size: 50% !important; }
  .c11,
  .c10,
  .c9,
  .c8,
  .c7,
  .c6,
  .c5,
  .c4,
  .c3,
  .c2,
  .c1 {
    width: 100%; }
  h1 {
    font-size: 6.4rem; }
  h2 {
    font-size: 2.5rem; }
  p {
    font-size: 1.6rem; }
  .logo-text .logo-main {
    font-size: 1.5em;
    letter-spacing: 0.15em; }
  #masthead {
    align-items: center; }
    #masthead .middle-logo {
      display: none; }
    #masthead .main-navigation {
      display: none; }
  #mmenu,
  #open-mmenu {
    display: block; }
  /* générale */
  .section-title {
    padding: 20px 0; }
  article .text-image .content {
    padding-right: 0; }
  article .text-image .image {
    padding: 20px 0; }
  article .realisations .section-title .voir-tous {
    margin-top: 9px; }
  article .realisations .realisation figure .overlay h3:after {
    margin-top: 6px; }
  /* accueil */
  .home-page .home-slider .owl-item .overlay {
    margin-top: 37px; }
    .home-page .home-slider .owl-item .overlay h2 {
      margin-bottom: 10px; }
  .home-page .home-slider .owl-dots {
    top: auto;
    bottom: 10px;
    margin-top: 0;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
    .home-page .home-slider .owl-dots .c12 {
      flex-direction: row;
      align-items: center;
      justify-content: center; }
    .home-page .home-slider .owl-dots .owl-dot {
      height: 6px;
      width: 28px;
      margin-bottom: 0;
      margin-right: 10px; }
  .home-page .services .section-background {
    display: none; }
  .home-page .services .section-title h3, .home-page .services .section-title h2 {
    color: #6c6b62; }
  .home-page .services .service {
    padding: 0;
    text-align: center; }
    .home-page .services .service .image {
      padding-top: 15px;
      background: url("../images/services_parallax.jpg") no-repeat -15px 0; }
  .home-page .entreprise .content .signature {
    float: left; }
  /* contact */
  .contact-page .informations .coordonnes {
    padding-right: 0; }
    .contact-page .informations .coordonnes .infos .c3 {
      width: 25%; }
    .contact-page .informations .coordonnes .infos .c9 {
      width: 75%; }
  .contact-page .contact-form .formulaire-contact {
    padding: 20px 0; }
  /* footer */
  #site-footer .inner-footer {
    padding: 30px 0; }
    #site-footer .inner-footer .copyright p .sep {
      height: 21px;
      margin: 0 5px; }
  .left-side, .right-side {
    padding: 0px; }
  .img-before-content, .remove {
    display: none; }
  .single-service {
    background-color: #6c6b62; } }

@media all and (max-width: 705px) {
  .realisations .realisations-wrapper > .c3 {
    width: 50%; } }

@media all and (max-width: 680px) {
  .home-page .home-slider .owl-item img {
    width: auto;
    max-width: none;
    height: 320px; } }

@media all and (max-width: 560px) {
  .contact-page .contact-form .formulaire-contact .left-input,
  .contact-page .contact-form .formulaire-contact .right-input {
    float: none;
    width: 100%; }
  #site-footer .inner-footer .footer-logo {
    float: none; }
  #site-footer .inner-footer .copyright {
    float: none;
    margin-top: 10px; } }

@media all and (max-width: 450px) {
  .realisations .realisations-wrapper > .c3 {
    width: 100%; } }
