// general responsive value
$maxWidth: 1200px;
$minWidth: 768px;
$c12Gutter: 15px;


// color
$color: #6c6b62;
$color2: #f2e257;


// mixin
@mixin square($pos: before, $mb: 0px){
	&{
	
		&:#{$pos}{
			content: "";
			display: inline-block;
			width: 9px;
			height: 8px;
			background-color: #f2e257;
			margin-#{if($pos == before, right, left)}: 10px;
			margin-bottom: $mb;
		}
		
		@media only screen and (max-width: $minWidth) {
			
			&:#{$pos}{
				width: 7px;
				height: 6px;
				margin-#{if($pos == before, right, left)}: 6px;
			}
		}
	}
}