@import "global";

//****************************************************
//	Variables & functions
//****************************************************
@mixin hover-transition($time:0.5s){
	-webkit-transition:all $time;
	transition:all $time;
	
	&:hover{text-decoration: none;}
}


@mixin transform($val){
	-ms-transform: $val;
	-webkit-transform: $val;
	-moz-transform: $val;
	-o-transform: $val;
	transform: $val;
}


@mixin align-middle($horz:false){
	position: absolute;
	@if $horz{
		left: 50%;
		-ms-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);
	} @else {
		top: 50%;
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}


@mixin placeholderColor($Pcolor, $opacity:1){
    ::-webkit-input-placeholder { /* WebKit browsers */
        color:$Pcolor;
        opacity:$opacity;
        @content;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color:$Pcolor;
       opacity:$opacity;
       @content;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color:$Pcolor;
       opacity:$opacity;
       @content;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
       color:$Pcolor;
       opacity:$opacity;
       @content;
    }
}

/* flexbox */
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
  -moz-box-ordinal-group: $val;     
  -ms-flex-order: $val;     
  -webkit-order: $val;  
  order: $val;
}


@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
      @each $property in $properties {
        #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
    }

    @media screen and (min-width: $max-vw) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}



@mixin animation($delay, $duration, $animation){
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards;

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: forwards;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards;
}

@mixin keyframe($animation_name){
    @-webkit-keyframes #{$animation_name}{
        @content;
    }

    @-moz-keyframes #{$animation_name}{
        @content;
    }

    @-o-keyframes #{$animation_name}{
        @content;
    }

    @keyframes #{$animation_name}{
        @content;
    }
}

// ******************
// EXEMPLE
// ******************
// @include keyframe(slideInLeft) {
//    0% {
//        left: -2000px;
//    }
//
//    100% {
//        left: 0px;
//    }
// }
//
// @include animation(0.7s, 1.5s, slideInLeft);