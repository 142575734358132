@import "utils";


/*****************************************************
	Custom Responsive CSS  (12 colonnes)
/*****************************************************
/*****************************************************
/*****************************************************/

/* Grandeur max du site */
.c12 {
	clear:both;
	margin:0 auto;
	max-width: $maxWidth;
	
	&.gutter { padding-left:$c12Gutter; padding-right:$c12Gutter; }
}

.clearfix:after,
.c12:after {
	content: " "; /* Older browser do not support empty content */
	visibility: hidden;
	display: block;
	height: 0;
	clear: both;
}

.c11,
.c10,
.c9,
.c8,
.c7,
.c6,
.c5,
.c4,
.c3,
.c2,
.c1 {
	float:left;
	min-height:1px;
}

.c12 { width:100%; }
.c11 { width:91.6666%; }
.c10 { width:83.3333%; }
.c9  { width:75%; }
.c8  { width:66.6666%; }
.c7  { width:58.3333%; }
.c6  { width:50%; }
.c5  { width:41.6666%; }
.c4  { width:33.3333%; }
.c3  { width:25%; }
.c2  { width:16.6666%; }
.c1  { width:8.3333%; }


/*
	Not yet mobile
*/
@media only screen and (max-width: 1400px) and (min-width: 1200px) {
	.logo-text a{
		font-size: 1.6rem;
	}
	#masthead{
		padding-top: 30px;
		padding-bottom: 30px;
		
		.middle-logo{
			img{
				width: 300px;
			}
		}
	}
	
	.main-navigation .nav-menu .menu-item{
		margin:0 15px;
		
		a{
			font-size: 1.8rem;
		}

		.sub-nav{
			margin-top: 30px;
		}
	}
}

@media only screen and (max-width: 1200px) {
	.logo-text{
		a{
			@include fluid-type(font-size, $minWidth, $maxWidth, 11px, 16px);
		}
	}
	
	#masthead{
		padding-top: 20px;
		padding-bottom: 20px;
		
		.middle-logo{
			img{
				@include fluid-type(width, $minWidth, $maxWidth, 180px, 300px);
			}
		}
	}
	
	.main-navigation{
		.nav-menu{
			.menu-item{
				@include fluid-type(margin-left margin-right, $minWidth, $maxWidth, 7px, 15px);
				@include fluid-type(padding-top, $minWidth, $maxWidth, 14px, 25px);
				@include fluid-type(border-top-width, $minWidth, $maxWidth, 6px, 8px);
				
				a{
					@include fluid-type(font-size, $minWidth, $maxWidth, 14px, 18px);
				}

				.sub-nav{
					margin-top: 20px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	#mmenu,
	#open-mmenu { display:block; }

	#masthead{
		.main-navigation{
			display: none;
		}
	}
}

@media only screen and (max-width: 1000px) {
	.realisations{
		.realisations-wrapper{
			> .c3{
				width: 33.3333%;
			}	
		}
	}
}

@media only screen and (max-width: $minWidth) {
	html { font-size: 50% !important; }
	
	.c11,
	.c10,
	.c9,
	.c8,
	.c7,
	.c6,
	.c5,
	.c4,
	.c3,
	.c2,
	.c1 {
		width:100%;
	}
	
	h1{
		font-size:6.4rem;
	}
	h2{
		font-size:2.5rem;
	}
	
	p{
		font-size: 1.6rem;
	}
	
	.logo-text .logo-main{
		font-size: 1.5em;
		letter-spacing: 0.15em;
	}
	
	#masthead{
		align-items: center;
		
		.middle-logo{
			display: none;
		}
		.main-navigation{
			display: none;
		}
	}
	
	
	#mmenu,
	#open-mmenu { display:block; }
	
	
	/* générale */
	.section-title{
		padding:20px 0;
	}
	article{
		.text-image{
			.content{
				padding-right: 0;
			}
			
			.image{
				padding: 20px 0;
			}
		}
		
		.realisations{
			.section-title{
				.voir-tous{
					margin-top: 9px;
				}
			}
			
			.realisation{
				figure{
					.overlay{
						h3{
							&:after{
								margin-top: 6px;
							}
						}
					}
				}
			}
		}
	}
	
	
	/* accueil */
	.home-page{
		.home-slider{
			.owl-item{
				
				.overlay{
					margin-top: 37px;
					
					h2{
						margin-bottom: 10px;
					}
				}
			}
			
			.owl-dots{
				top: auto;
				bottom: 10px;
				margin-top: 0;
				@include transform(translateY(0));
				
				.c12{
					flex-direction: row;
					align-items: center;
					justify-content: center;
				}
				
				.owl-dot{
					height: 6px;
					width: 28px;
					margin-bottom: 0;
					margin-right: 10px;
				}
			}
		}
		
		.services{
			.section-background{
				display: none;
			}
			
			.section-title{
				h3, h2{
					color: $color;
				}
			}
			
			.service{
				padding: 0;
				text-align: center;
				
				.image{
					padding-top: 15px;
					background: url("../images/services_parallax.jpg") no-repeat -15px 0;
				}
			}
		}
		
		.entreprise{
			.content{
				.signature{
					float: left;
				}
			}
		}
	}
	
	/* contact */
	.contact-page{
		.informations{
			.coordonnes{
				padding-right: 0;
				
				.infos{
					.c3{
						width: 25%;
					}
					.c9{
						width: 75%;
					}
				}
			}
		}
		
		.contact-form{
			.formulaire-contact{
				padding: 20px 0;
			}
		}
	}
	
	
	/* footer */
	#site-footer .inner-footer{
		padding: 30px 0;
		
		.copyright p .sep{
			height: 21px;
			margin: 0 5px;
		}
	}
    
    .left-side,.right-side{padding:0px;}
    .img-before-content,.remove{
      display:none;
    }
    .single-service{
        background-color:$color;
    }
}

@media all and (max-width: 705px) {
	.realisations{
		.realisations-wrapper{
			> .c3{
				width: 50%;
			}	
		}
	}
}

@media all and (max-width: 680px) {
	.home-page{
		.home-slider{
			.owl-item{
				img{
					width: auto;
					max-width: none;
					height: 320px;
				}
			}
		}
	}
}

@media all and (max-width: 560px) {
	
	.contact-page .contact-form{
		.formulaire-contact{
			.left-input,
			.right-input{
				float: none;
				width: 100%;
			}
		}
	}
	
	#site-footer .inner-footer{
		.footer-logo{
			float: none;
		}
		
		.copyright{
			float: none;
			margin-top: 10px;
		}
	}
	
}

@media all and (max-width: 450px) {
	.realisations{
		.realisations-wrapper{
			> .c3{
				width: 100%;
			}
		}
	}
}